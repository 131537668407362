<template>
  <div
    v-if="!loading && data.applicants.data.length > 0"
    class="row"
  >
    <h3 class="content-header-title float-start mb-0 col-12 mb-1">
      Evaluations - {{ action.replaceAll('-', ' ') }}<br>
    </h3>
    <CompleteTable
      :sorted-field="sortedField"
      :data="data.applicants.data"
      :sort-mapping="sortMapping"
      :title="'evaluations'"
      :total="applicantsTotal"
      :fetch-path="'users/fetchEvaluationsList'"
      :filter-path="'-'"
      :custom-filters="filters"
      @appliedFilters="appliedFilters($event)"
      @deleteFilter="deleteFilter($event)"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <i data-feather="more-vertical" />
            </template>
            <div
              class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
            >
              <b-dropdown-item
                v-if="field.promotion"
                @click="$router.push({name: 'researcher.administration.promotion-history.see-evaluation', params: {
                  user: field.user.id,
                  convo: field.promotion.id
                }})"
              >
                <i
                  class="me-50"
                  data-feather="eye"
                /> View candidate report
              </b-dropdown-item>
              <b-dropdown-item
                v-if="field.convo"
                @click="$emit('openCanvaCall', field)"
              >
                <i
                  class="me-50"
                  data-feather="eye"
                /> View call evaluation
              </b-dropdown-item>
              <b-dropdown-item
                v-if="field.promotion"
                @click="openCanva(field)"
              >
                <i
                  class="me-50"
                  data-feather="eye"
                /> View promotion evaluation
              </b-dropdown-item>
            </div>
          </b-dropdown>
          <a
            href=""
            class="btn btn-icon btn-light-secondary d-block d-sm-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasBottom"
          ><i
            data-feather="more-vertical"
          /></a>
        </td>
      </template>
      <template #customfilters>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Applicant</label>
          <v-select
            label="name"
            :options="users"
            :get-option-key="option => option.id"
            placeholder="Type to search..."
            @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
            @input="saveFilter($event, 'applicant')"
          />
        </div>
        <div class="mb-1">
          <label
            for="name"
            class="form-label"
          >Call</label>
          <v-select
            multiple
            label="title"
            :options="convos"
            :get-option-key="option => option.id"
            @input="saveFilter($event, 'convos')"
          />
        </div>
        <!-- <div class="mb-1">
                <label
                  for="name"
                  class="form-label"
                >Status applicant</label>
                <v-select
                  multiple
                  label="name"
                  :options="Object.values(data.status)"
                  :get-option-key="option => option"
                  @input="saveFilter($event, 'status')"
                />
              </div> -->
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Evaluated</label>
          <v-select
            label="label"
            :options="[
              {label: 'Yes', name: 'Evaluated', value: true},
              {label: 'No', name: 'No evaluated', value: false},
            ]"
            :get-option-key="option => option.name"
            @input="saveFilter($event, 'evaluated')"
          />
        </div>
      </template>
    </CompleteTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Vue from 'vue'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
  },
  props: {
    action: { type: String, required: true },
  },
  data() {
    return {
      // Promotion
      selected: {},
      selectedTitle: '',
      type: null,
      researcher: null,
      // Senior & Academia
      selectedItem: {},
      title: 'Evaluations by',
      sortedField: 'name',
      defaultFields: [
        {
          name: 'Applicant',
          checked: true,
          order: 1,
        },
        {
          name: 'Evaluated',
          checked: true,
          order: 2,
        },
        {
          name: 'Call',
          checked: true,
          order: 3,
        },
        {
          name: 'Status',
          checked: true,
          order: 4,
        },
      ],
      sortMapping: {
        Applicant: 'name',
        Evaluated: 'graded',
        Call: 'code',
        Status: 'status',
      },
      loading: true,
      data: [],
      applicantsTotal: 0,
      filters: {},
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      dataView: 'users/dataEvaluationsView',
      users: 'users/fakeUsers',
      convos: 'convo/convos',
      // filters: 'filters/filters',
      page: 'pagination/page',
      perPage: 'pagination/perPage',
    }),
  },
  watch: {
    page() {
      this.resetEvaluations()
    },
    perPage() {
      this.resetEvaluations()
    },
  },
  async mounted() {
    this.loading = true
    this.resetFilters()
    await this.resetEvaluations()
    this.loading = false

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    resetFilters() {
      const filters = JSON.parse(localStorage.getItem('filters')) ?? {}
      this.filters = filters[`${this.$route.name}-${this.action}`] ?? {}
    },
    async resetEvaluations() {
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/get-evaluations-table/${this.$route.params.id}/${this.action}/filter?page=${this.page ?? 1}&per_page=${this.perPage ?? 1}`, this.filters)
      this.data = resp.data.data
      this.applicantsTotal = resp.data.total

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 250)
    },
    deleteFilter(index) {
      this.$store.dispatch('filters/deleteFiltersCustom', { index, action: this.action })
      this.resetFilters()
      this.resetEvaluations()
    },
    async appliedFilters() {
      this.resetFilters()
      this.resetEvaluations()
    },
    openCanva(evaluation) {
      const data = evaluation
      data.type = this.action
      this.$emit('openCanva', data)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    saveFilter(value, field) {
      if (field === 'convos') {
        value.map(e => {
          e.name = e.title
          return e
        })
      }

      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
        customParam: this.action,
      })
    },
  },
}
</script>
