var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.data.applicants.data.length > 0)?_c('div',{staticClass:"row"},[_c('h3',{staticClass:"content-header-title float-start mb-0 col-12 mb-1"},[_vm._v(" Evaluations - "+_vm._s(_vm.action.replaceAll('-', ' '))),_c('br')]),_c('CompleteTable',{attrs:{"sorted-field":_vm.sortedField,"data":_vm.data.applicants.data,"sort-mapping":_vm.sortMapping,"title":'evaluations',"total":_vm.applicantsTotal,"fetch-path":'users/fetchEvaluationsList',"filter-path":'-',"custom-filters":_vm.filters},on:{"appliedFilters":function($event){return _vm.appliedFilters($event)},"deleteFilter":function($event){return _vm.deleteFilter($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var field = ref.field;
return [_c('td',{staticClass:"text-end"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","toggle-class":"p-0","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{attrs:{"data-feather":"more-vertical"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"btn-group dropup dropdown-icon-wrapper d-none d-sm-block"},[(field.promotion)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({name: 'researcher.administration.promotion-history.see-evaluation', params: {
                user: field.user.id,
                convo: field.promotion.id
              }})}}},[_c('i',{staticClass:"me-50",attrs:{"data-feather":"eye"}}),_vm._v(" View candidate report ")]):_vm._e(),(field.convo)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('openCanvaCall', field)}}},[_c('i',{staticClass:"me-50",attrs:{"data-feather":"eye"}}),_vm._v(" View call evaluation ")]):_vm._e(),(field.promotion)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openCanva(field)}}},[_c('i',{staticClass:"me-50",attrs:{"data-feather":"eye"}}),_vm._v(" View promotion evaluation ")]):_vm._e()],1)]),_c('a',{staticClass:"btn btn-icon btn-light-secondary d-block d-sm-none",attrs:{"href":"","data-bs-toggle":"offcanvas","data-bs-target":"#offcanvasMenu","aria-controls":"offcanvasBottom"}},[_c('i',{attrs:{"data-feather":"more-vertical"}})])],1)]}},{key:"customfilters",fn:function(){return [_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Applicant")]),_c('v-select',{attrs:{"label":"name","options":_vm.users,"get-option-key":function (option) { return option.id; },"placeholder":"Type to search..."},on:{"search":function($event){return _vm.onSearch({ name: $event }, 'users/fetchFakeUsers')},"input":function($event){return _vm.saveFilter($event, 'applicant')}}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Call")]),_c('v-select',{attrs:{"multiple":"","label":"title","options":_vm.convos,"get-option-key":function (option) { return option.id; }},on:{"input":function($event){return _vm.saveFilter($event, 'convos')}}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Evaluated")]),_c('v-select',{attrs:{"label":"label","options":[
            {label: 'Yes', name: 'Evaluated', value: true},
            {label: 'No', name: 'No evaluated', value: false} ],"get-option-key":function (option) { return option.name; }},on:{"input":function($event){return _vm.saveFilter($event, 'evaluated')}}})],1)]},proxy:true}],null,false,3676328036)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }